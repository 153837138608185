import React from "react";
import Menu from "../../../Common/Menu";
import Nav from "../Nav";
import { Link, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import api from "../../../Common/api";
import estados from '../../../Common/Estados';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { botonSegunEstado, FormatoHora } from "../../../Common/Funciones";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />;
    };
}

class DetalleAlertaDenuncias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Detalle: [],
            currentImageIndex: 0
        };
    }

    CargarDetalle() {
        const IdParam = this.props.match.params.id;
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(api + "alert/complaintperson/" + IdParam + "/", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    Detalle: result,
                });
            })
            .catch(error => console.log('error', error));
    }

    changeImage = (index) => {
        this.setState({ currentImageIndex: index });
    };

    CamabiarEstado(id, value) {
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + access_token);
       
        var raw = JSON.stringify({
            "status": value,
            "description": "Sos valido"
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(api + "alert/complaintperson/" + id + "/", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        this.CargarDetalle();
    }

    componentDidMount() {
        this.CargarDetalle();
    }

    render() {
        const icon = L.icon({
            iconUrl: "./img/Icon-primary.png",
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
        });
        return (
            <div id="detalleAlertaDenuncias">
                <Menu />
                <div className="row">
                    <Nav />
                    <div className="col flex-grow-1">
                        <div className="container-fluid d-flex flex-column h-100">
                            <div className="border my-1 p-1">
                                <Link
                                    className="btn btn-link rounded-0 border border-dark shadow-none my-2 d-flex justify-content-center align-items-center Link"
                                    to="/Alertas/Denuncias"
                                >
                                    <KeyboardBackspaceIcon />
                                    <span className="ml-2 d-none d-md-block">Regresar</span>
                                </Link>
                                <div className="row flex-grow-1">
                                    <div className="col border d-flex flex-column justify-content-center">
                                        <div>
                                            <h1 className="mb-3">
                                                {this.state.Detalle.type_name ? this.state.Detalle.type_name : "Sin Titulo"}
                                            </h1>
                                            <p className="mb-0 text-muted">
                                                {this.state.Detalle.data_informer ? this.state.Detalle.data_informer : "Sin descripción"}
                                            </p>
                                            <p className="mb-0 text-muted">
                                                Creado: {FormatoHora(this.state.Detalle.date_time_created)}
                                            </p>
                                            <p className="mb-0 text-muted">
                                                Finalizado:{" "}
                                                {FormatoHora(this.state.Detalle.date_time_terminated) ? FormatoHora(this.state.Detalle.date_time_terminated) : "Sin Terminar"}
                                            </p>
                                            {botonSegunEstado(this.state.Detalle.status)}
                                        </div>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <button
                                                            className="btn btn-dark btn-sm"
                                                            onClick={() => this.CamabiarEstado(this.state.Detalle.id, document.getElementById('estado').value)}
                                                        >
                                                            Cambiar
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <select className="form-select mb-3" id="estado" name="estado" defaultValue="Estado">
                                                            {estados.map((estado) => (
                                                                <option key={estado.value} value={estado.value}>{estado.label}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.state.Detalle?.photos_compliment && (
                                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                                <ol className="carousel-indicators">
                                                    {this.state.Detalle.photos_compliment.map((photo, index) => (
                                                        <li
                                                            key={index}
                                                            data-target="#carouselExampleIndicators"
                                                            data-slide-to={index}
                                                            className={index === this.state.currentImageIndex ? "active" : ""}
                                                        ></li>
                                                    ))}
                                                </ol>
                                                <div className="carousel-inner">
                                                    {this.state.Detalle.photos_compliment.map((photo, index) => (
                                                        <div
                                                            key={`photo_${index}`}
                                                            className={`carousel-item ${index === this.state.currentImageIndex ? "active" : ""}`}
                                                        >
                                                            <img
                                                                className="d-block w-100"
                                                                src={photo.url}
                                                                alt={`Slide ${index}`}
                                                                onClick={() => this.changeImage(index)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <button
                                                    className="carousel-control-prev"
                                                    type="button"
                                                    data-target="#carouselExampleIndicators"
                                                    data-slide="prev"
                                                    onClick={() => this.changeImage((this.state.currentImageIndex - 1 + this.state.Detalle.photos_compliment.length) % this.state.Detalle.photos_compliment.length)}
                                                >
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="sr-only">Previous</span>
                                                </button>
                                                <button
                                                    className="carousel-control-next"
                                                    type="button"
                                                    data-target="#carouselExampleIndicators"
                                                    data-slide="next"
                                                    onClick={() => this.changeImage((this.state.currentImageIndex + 1) % this.state.Detalle.photos_compliment.length)}
                                                >
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="sr-only">Next</span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col">
                                        <div className="border map w-100">
                                            <MapContainer center={[this.state.Detalle.lat || -9.9308, this.state.Detalle.lng || -76.2421]} zoom={14} scrollWheelZoom={true}>
                                                <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker position={[this.state.Detalle.lat || -9.9308, this.state.Detalle.lng || -76.2421]} icon={icon}>
                                                    <Popup>
                                                        <p>lat: {this.state.Detalle.lat || "Sin datos "} lng: {this.state.Detalle.lng || "Sin datos"}</p>
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DetalleAlertaDenuncias);
