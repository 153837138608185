import { iconCamionetaSerenazgo, iconCar, iconMotorcycle, iconUnknowDevice } from "./icons"
import { Marker, Popup } from "react-leaflet";

const iconsByVehicle = {
    "default": iconUnknowDevice,
    "offroad": iconCamionetaSerenazgo,
    "motorcycle": iconMotorcycle,
    "car": iconCar
}


export const DeviceMarkers = ({ data }) => {
    const icon = iconsByVehicle[data.category] || iconsByVehicle["default"]
    return (
        <div key={data.id}>
            {data.latitude !== "null" && data.longitude !== "null" ? (
                <Marker
                    position={[data.latitude, data.longitude]}
                    icon={icon}
                >
                    <Popup>
                        <p className="text-center text-left">
                            {data.online}
                        </p>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th className="text-center text-left">
                                        PLACA
                                    </th>
                                    <th className="text-center text-left">
                                        CATEGORIA
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center text-left">
                                        {data.name}
                                    </td>
                                    <td className="text-center text-left">
                                        {data.category || "sin categoría"}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-center text-left">
                                        Telefono
                                    </th>
                                    <th className="text-center text-left">
                                        MARCA
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center text-left">
                                        {/* {data.contact} */}
                                        {data.phone_number}
                                    </td>
                                    <td className="text-center text-left">
                                        {data.car_marca}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-center text-left">
                                        GPS NUM
                                    </th>
                                    <th className="text-center text-left">
                                        VELOCIDAD
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center text-left">
                                        {data.gps_num}
                                    </td>
                                    <td className="text-center text-left">
                                        {data.speed}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr></hr>
                        <p className="text-center text-left text-40">
                            {" "}
                            lat: {data.latitude} lng: {data.longitude}{" "}
                        </p>

                    </Popup>
                </Marker>
            ) : null}
        </div>
    )
}