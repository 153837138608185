function apiGet(){
    let apiTemp = "http://147.182.180.146:7777/";
    let conexioneBussines = sessionStorage.getItem("conexioneBussines");
    if (conexioneBussines) {
      let dataJSON = JSON.parse(conexioneBussines);
      apiTemp = "http://"+dataJSON.data.ip+":"+dataJSON.data.port+"/";
    }
    return apiTemp;
}
const api = apiGet();
export default api