export const mappDevices = (data) => {
    return data.map((item) => {
        return {
            ...item,
            online: "",
            latitude: "",
            longitude: "",
            car_marca: "default",
            gps_num: "",
            speed: 0,
            online_date: "",
            phone_number: item.phone,
        };
    });
}
