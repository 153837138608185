import React from "react";
import { LogoPage } from "../../Common/Funciones";
class Carga extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoPage: LogoPage(),
    };
  }
  render() {
    return (
      <div id="carga">
        <div className="logo">
          <img src={this.state.logoPage} alt="logo muni"></img>
          <div className="carga">
            <div className="loader"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Carga;
