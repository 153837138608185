import React from "react";
import api from "../../Common/api";
import Menu from "../../Common/Menu"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
class Perfil extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            User: null,
            // otros estados que puedas tener
        };
    }

    CargarPerfil() {
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(api + "auth/info/", requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ User: data }));
    }

    componentDidMount() {
        this.CargarPerfil();
    }

    render() {
        const { User } = this.state;
        return (
            <div id="perfil">
                <Menu />
                <Link className="d-flex align-items-center border p-2 Link" to="/">
                    <KeyboardBackspaceIcon />
                    <span className="ml-2 d-none d-md-block">Regresar</span>
                </Link>

                <div className="my-4 d-flex justify-content-center align-items-center">
                    <div className="border rounded p-3 text-center w-50 mx-auto">
                        {User && (
                            <div>
                                <p>Nombre</p>
                                <h1 className="mb-3" style={{ color: '#4CAF50' }}>{User.name}</h1>
                                <br/>
                                <p>Contacto</p>
                                <h3 style={{ fontSize: '1.5rem' }}>{User.contact}</h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Perfil;
