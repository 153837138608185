import Swal from "sweetalert2";
import { API_DEVICES, API_SOCKET } from "./config"
import { w3cwebsocket as WSocket } from "websocket";
import { mappDevices } from "./mapper";

export const getAllDevices = async (mailTraccar) => {
    // get all devices from the traccar api
    const res = await fetch(API_DEVICES, {
        method: "GET",
        headers: {
            "Authorization": "Basic " + btoa(mailTraccar + ":123456"),
        },
    })
    if (res.status != 200) {
        Swal.fire("Error", "No se pudo obtener los dispositivos, contace soporte, error: 'devices'", "error");
        return [];
    }
    const json_data =  await res.json();
    return mappDevices(json_data);
}


export const getSocketConnection = () => {
    return new WSocket(API_SOCKET)
}
