import React from "react";
import "../../css/style.css";
import api from "../../Common/api";
import Carga from "../Common/Carga";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import { LogoPage } from "../../Common/Funciones";

class _UserLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPass: null,
      error: null,
      wait: null,
      success: null,
      msg: "",
      checkbox: false,
      carga: null,
      logoPage: LogoPage(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#4CAF50";
  }

  BtnShowPass() {
    if (this.state.showPass === 1) {
      this.setState({
        showPass: null,
      });
    } else {
      this.setState({
        showPass: 1,
      });
    }
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
    if (t_name === "checkbox") {
      this.setState({
        checkbox: !this.state.checkbox,
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      error: null,
      wait: true,
      success: null,
      msg: "",
      carga: 1,
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: this.state.email,
      password: this.state.password,
    });

    fetch(api + "auth/login/", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 400) {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
        return response.json();
      })
      .then((result) => {
        if (result.tokens) {
          localStorage.setItem("access_token", result.tokens.access);
          localStorage.setItem("refresh_token", result.tokens.refresh);
          localStorage.setItem("dni", result.tokens.dni);
          this.props.cargarPagina();
        }
        this.setState({
          error: null,
          wait: true,
          success: null,
          msg: "",
          carga: null,
        });
      })
      .catch((error) => {
        this.setState({
          error: 1,
          wait: null,
          success: null,
          msg: error.message,
          carga: null,
        });

        // Mostrar la alerta de error
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 3000,
        });
      });
  }

  render() {
    return (
      <div id="login">
        {this.state.carga ? <Carga /> : ""}
        <div className="container">
          <input type="checkbox" id="flip" />
          <div className="cover">
            <div className="front">
              <img className="img-muni" src={this.state.logoPage} alt="" />
              <div className="text">
                <img
                  className="img-lib"
                  src="img/business-people-having-meeting.jpg"
                  alt="personas de negocios teniendo una reunión"
                />
              </div>
            </div>
          </div>
          <div className="forms">
            <div className="form-content">
              <div className="login-form">
                
                <div className="title">Inicia sesión</div>
                <div className="logoDiv">
                  <img className="logoLogin" src={this.state.logoPage} alt="" />
                </div>
                <form className="user" onSubmit={this.handleSubmit}>
                  <div className="input-boxes">
                    <div className="input-box">
                      <i className="fas fa-envelope"></i>
                      <input
                        type="email"
                        id="correo"
                        placeholder="Introduce tu correo electrónico"
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        required
                      />
                    </div>
                    <div className="input-box">
                      <i className="fas fa-lock"></i>
                      <input
                        type={this.state.showPass ? "text" : "password"}
                        id="pass"
                        placeholder="Ingresa tu contraseña"
                        name="password"
                        onChange={this.handleChange}
                        required
                      />
                      <div
                        className="show-pass"
                        onClick={() => this.BtnShowPass()}
                      >
                        {this.state.showPass ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </div>
                    </div>
                    <div className="text">
                      <a href="/">¿Has olvidado tu contraseña?</a>
                    </div>
                    <div className="button input-box">
                      <input type="submit" value="Iniciar" />
                    </div>
                    <div className="text sign-up-text">
                      ¿No tienes una cuenta?{" "}
                      <label htmlFor="flip">Regístrese ahora</label>
                    </div>
                  </div>
                </form>
              </div>

              <div className="signup-form">
                <div className="title">Registrarse</div>
                <form action="#">
                  <div className="input-boxes">
                    <div className="input-box">
                      <i className="fas fa-user"></i>
                      <input
                        type="text"
                        placeholder="Introduzca su nombre"
                        required
                      />
                    </div>
                    <div className="input-box">
                      <i className="fas fa-envelope"></i>
                      <input
                        type="text"
                        placeholder="Introduce tu correo electrónico"
                        required
                      />
                    </div>
                    <div className="input-box">
                      <i className="fas fa-lock"></i>
                      <input
                        type={this.state.showPass ? "text" : "password"}
                        placeholder="Ingresa tu contraseña"
                        required
                      />
                      <div
                        className="show-pass"
                        onClick={() => this.BtnShowPass()}
                      >
                        {this.state.showPass ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </div>
                    </div>
                    <div className="button input-box">
                      <input type="submit" value="Registrar" />
                    </div>
                    <div className="text sign-up-text">
                      ¿Ya tienes una cuenta?{" "}
                      <label htmlFor="flip">Inicia sesión ahora</label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default _UserLogin;
