import React from "react";
import Menu from "../../../Common/Menu"
import Nav from "../Nav"
import SearchIcon from '@mui/icons-material/Search';
import SosIcon from '@mui/icons-material/Sos';
import api from "../../../Common/api";
import { Link } from 'react-router-dom';
import { botonSegunEstado, FormatoHora } from "../../../Common/Funciones";

class AlertaSOS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Detalles: [],
        };
    }

    CargarDetalle() {
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(api + "alert/historypersonsos/?person_id=1", requestOptions)
            .then(response => response.json())
            .then(result => {
                const filteredResult = result.filter(item => item.lat !== 'null' && item.lng !== 'null' && item.lat !== null && item.lng !== null);
                this.setState({
                    Detalles: result,
                })
            })
            .catch(error => console.log('error', error));
    }

    BuscarDetalle(dni) {

        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(api + "alert/historypersonsos/?person_id__dni=" + dni, requestOptions)
            .then(response => response.json())
            .then(result => {
                const filteredResult = result.filter(item => item.lat !== 'null' && item.lng !== 'null' && item.lat !== null && item.lng !== null);
                this.setState({
                    Detalles: result,
                })
            })
            .catch(error => console.log('error', error));
    }

    componentDidMount() {
        this.CargarDetalle();
    }

    render() {
        const { Detalles } = this.state;
        return (
            <div id="alertaSOS">
                <Menu />
                <div className="row">
                    <Nav />
                    <div className="col-12 col-md-10 border d-flex flex-column justify-content-center align-items-center">
                        <div className="row my-2">
                            <div className="col-md-12 mb-3">
                                <div className="d-flex align-items-center">
                                    <SosIcon style={{ fontSize: "2rem" }} />
                                    <h1 className="ml-2 mb-0" style={{ fontSize: "2rem" }}>Alerta SOS</h1>
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input id="search-input" type="text" className="form-control" placeholder="Buscar..." />
                            <div onClick={() => this.BuscarDetalle(document.getElementById('search-input').value)} className="input-group-append pointer">
                                <span className="input-group-text"><SearchIcon /></span>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-md-2 ">
                            {Detalles.map((Det) => (
                                <Link key={Det.id} className="col-md-6 mb-3 Link" to={`/Alertas/Sos/${Det.id}`}>
                                    <div className="border rounded p-3">
                                        <p>
                                            Descripción: {Det.description}
                                        </p>
                                        <p>
                                            Creado: {FormatoHora(Det.date_time_created)} Finalizado: {FormatoHora(Det.date_time_terminated)}
                                        </p>
                                        <p>
                                            {botonSegunEstado(Det.status)}
                                        </p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AlertaSOS;