import L from 'leaflet'

export const iconCamionetaSerenazgo = L.icon({
    iconUrl: "./img/camionetaserenazgo.png",
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

export const iconCar = L.icon({
    iconUrl: "./img/sos-car.png",
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

export const iconMotorcycle = L.icon({
    iconUrl: "./img/sos-motorcycle.png",
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});


export const iconUnknowDevice = L.icon({
    iconUrl: "./img/sos-unknow-device.png",
    //iconUrl: "./img/icon_local_nuevo.png",
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

export const iconSOSLocal = L.icon({
    iconUrl: "./img/icon_local.png",
    iconSize: [41, 60],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

export const iconNewSOSLocal = L.icon({
    iconUrl: "./img/icon_local_nuevo.png",
    iconSize: [41, 60],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});


