import React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import AlertaSOS from "./view/Alertas/SOS/AlertaSOS";
import DetalleAlertaSOS from "./view/Alertas/SOS/DetalleAlertaSOS";
import Mapa from "./view/Alertas/Mapa";
import Perfil from "./view/Usuario/Perfil";
import PlanesSociales from "./view/PlanesSociales/PlanesSociales";
import PrediosTributo from "./view/PrediosTributo/PrediosTributo";
import User from "./view/User/User";
import Denuncias from "./view/Alertas/Denuncia/Denuncias";
import TipoDenuncias from "./view/Alertas/Denuncia/TipoDenuncias";
import DetalleAlertaDenuncias from "./view/Alertas/Denuncia/DetalleAlertaDenuncias";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<User />} />
        <Route path="*" element={<User />} />

        <Route path="/Alertas/Mapa" element={<Mapa />} />
        <Route path="/Alertas/Denuncias" element={<Denuncias />} />
        <Route path="/Alertas/Sos" element={<AlertaSOS />} />
        
        <Route path="/Predios-Tributo" element={<PrediosTributo />} />
        <Route path="/Planes-Sociales" element={<PlanesSociales />} />

        <Route path="/Perfil" element={<Perfil />} />
        
        <Route path="/Alertas/Sos/:id" element={<DetalleAlertaSOS />} />
        <Route path="/Alertas/Denuncias/:id" element={<DetalleAlertaDenuncias />} />
        <Route path="/Alertas/Denuncias/TipoDenuncias" element={<TipoDenuncias />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
