import { iconNewSOSLocal, iconSOSLocal } from "./icons"
import { Marker, Popup } from "react-leaflet";
import { botonSegunEstado, FormatoHora } from "../../../Common/Funciones";
import estados from "../../../Common/Estados";

export const LocalAlert = ({ data }) => {
    return (
        <div key={data.person_id_id}>
            {data.lat !== "null" && data.lng !== "null" ? (
                <Marker
                    position={[data.lat, data.lng]}
                    icon={data.isNew ? iconNewSOSLocal : iconSOSLocal}
                >
                    <Popup>
                        <div className="d-flex flex-column text-center p-3">
                            <p className="m-0 mb-2">{botonSegunEstado(data.status)}</p>
                            <p className="m-0">Creado:{" "}{FormatoHora(data.date_time_update)}</p>
                        </div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th className="text-center text-left">
                                        Nombre
                                    </th>
                                    <th className="text-center text-left">
                                        DNI
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center text-left">
                                        {data.name}
                                    </td>
                                    <td className="text-center text-left">
                                        {data.dni}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-center text-left">
                                        Telefono
                                    </th>
                                    <th className="text-center text-left">
                                        Gmail
                                    </th>
                                </tr>
                                <tr>
                                    <td className="text-center text-left">
                                        {data.phone_number}
                                    </td>
                                    <td className="text-center text-left">
                                        {data.mail == null ? "Sin correo" : data.mail}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr></hr>
                        <p className="text-center text-left text-40">
                            {" "}
                            lat: {data.lat} lng: {data.lng}{" "}
                        </p>
                        <div className="d-flex justify-content-center">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        {/* <td colSpan={2}>
                                            <label>Descripción:</label>
                                            <input
                                                className="form-control"
                                                name="description"
                                                id="description"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.description}
                                            ></input>
                                        </td> */}
                                    </tr>
                                    <tr>
                                        {/* <td>
                                            <button
                                                className="btn btn-dark btn-sm"
                                                onClick={() =>
                                                    this.CamabiarEstado(
                                                        data.history_id.id
                                                            ? data.history_id.id
                                                            : data.history_id,
                                                        document.getElementById("estado")
                                                            .value
                                                    )
                                                }
                                            >
                                                Cambiar
                                            </button>
                                        </td> */}

                                        {/* <td>
                                            <select
                                                className="form-select mb-3"
                                                id="estado"
                                                name="estado"
                                                defaultValue="Estado"
                                            >
                                                {estados.map((estado) => (
                                                    <option
                                                        key={estado.value}
                                                        value={estado.value}
                                                    >
                                                        {estado.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </td> */}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Popup>
                </Marker>
            ) : null}
        </div>
    )
}
