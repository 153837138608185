import React from "react";

class PrediosTributo extends React.Component {

    render() {
        return (
            <div id="PrediosTributo">
                PrediosTributo
            </div>
        );
    }
}

export default PrediosTributo;
