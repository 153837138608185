import React from "react";

class PlanesSociales extends React.Component {

    render() {
        return (
            <div id="PlanesSociales">
                PlanesSociales
            </div>
        );
    }
}

export default PlanesSociales;
