import React from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HelpIcon from '@mui/icons-material/Help';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { LogoPage } from "./Funciones";
class CommonMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logoPage: LogoPage(),
        };
    }

    Cerrar() {
        localStorage.clear();
        window.location.href = "./";
    }

    CerrarSesion() {
        Swal.fire({
            title: "¿Estás seguro de que quieres cerrar la sesión?",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.isConfirmed) {
                this.Cerrar()
            } else if (result.isDenied) {
                Swal.fire("Los cambios no se guardan", "", "info");
            }
        });
    }
    render() {
        return (
            <div className="menu">
                <nav className="navbar navbar-expand navbar-dark">
                    <a className="navbar-brand" href="/">
                        <img src={this.state.logoPage} alt="Logo" height="50" />
                    </a>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item flex-grow-0">
                                <Link className="btn btn-link nav-link" to="/Perfil">
                                    <AccountCircleIcon />
                                </Link>
                            </li>
                            <li className="nav-item flex-grow-0">
                                <button className="btn btn-link nav-link">
                                    <HelpIcon />
                                </button>
                            </li>
                            <li className="nav-item flex-grow-0">
                                <button className="btn btn-link nav-link" onClick={() => this.CerrarSesion()}>
                                    <ExitToAppIcon />
                                </button>
                            </li>
                        </ul>
                    </div>

                </nav>
            </div>

        );
    }
}

export default CommonMenu;
