import React from 'react';
import { Link } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';
import ReportIcon from '@mui/icons-material/Report';
import SosIcon from '@mui/icons-material/Sos';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

class Nav extends React.Component {

    MenuActive(menu_name) {
        var urlActual = window.location.hash;
        urlActual = urlActual.substring(2);

        if (urlActual.startsWith(menu_name)) {
            var urlSegments = urlActual.split('/');
            if (urlSegments.length > 1) {
                return "btn btn-outline-success my-2 btn-block active d-flex justify-content-center align-items-center";
            } else {
                return "btn btn-outline-success my-2 btn-block d-flex justify-content-center align-items-center";
            }
        } else {
            return "btn btn-outline-success my-2 btn-block d-flex justify-content-center align-items-center";
        }
    }


    render() {
        return (
            <div id="nav" className="borderMenu">
                <div className="container borderMenu h-100 d-flex flex-column">
                    <Link className="btn btn-link rounded-0 border border-dark shadow-none my-2 d-flex justify-content-center align-items-center Link" to="/">
                        <KeyboardBackspaceIcon />
                        <span className="ml-2 d-none d-md-block">Regresar</span>
                    </Link>
                    <Link to="/Alertas/Mapa" className={this.MenuActive("Alertas/Mapa")} style={{ color: "#000", borderColor: "#4CAF50" }}>
                        <MapIcon />
                        <span className="ml-2 d-none d-md-block">Mapa</span>
                    </Link>
                    <Link to="/Alertas/Denuncias" className={this.MenuActive("Alertas/Denuncias")} style={{ color: "#000", borderColor: "#4CAF50" }} >
                        <ReportIcon />
                        <span className="ml-2 d-none d-md-block">Denuncias</span>
                    </Link>
                    <Link to="/Alertas/Sos" className={this.MenuActive("Alertas/Sos")} style={{ color: "#000", borderColor: "#4CAF50" }} >
                        <SosIcon />
                        <span className="ml-2 d-none d-md-block">Alerta SOS</span>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Nav;
